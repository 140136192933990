import '../style/common.css'
import './index.less'
import '../component/footer.less'
import '../component/header.less'
import footer from '../component/footer.js'
import headeryzt from '../component/headeryzt.js'
$('.header').append(headeryzt)
$('.footer').append(footer)

$('.a1').addClass('active')
