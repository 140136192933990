const footer = $(`<div class="footer-main">
  <div class="box">
   
    <div class="footer-logo"></div>
    <p class="tips">如果您希望享受客户服务、商务合作、媒体报道，请拨打客服电话：</p>
    <div class="tel-wrap">
      <div class="tel-icon"></div>
      <p class="tel">952592</div>
    </div>

    <div class="line"></div>
    <div class="tips-2"><i></i><p><a href="https://beian.miit.gov.cn" target="_blank">深圳市小赢普惠科技有限责任公司 Copyright ©2023 粤ICP备14057169号</a></p></div>
  </div>
</div>`)
export default footer
